@font-face {
  font-family: "Montserrat Regular";
  src: url("../../resources/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../../resources/fonts/Montserrat-Bold.ttf");
}

body {
  margin: 0;
  font-family: "Montserrat Regular";
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #fff;
  background-color: #e3dede !important;
  font-size: 18px;
  color: #494963 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


#root::after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-position-x: center;
  background-size: cover;
  background-position: center;
  background-color: #e3dede;
  // background-image: url(../../resources/images/bg.svg);
}

.full {
  width: 100%;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.bold {
  font-weight: 'bold' !important;
}

.cursor {
  cursor: pointer;
}

.padre {
  display: table;

  & .hijos {
    display: table-cell;
    vertical-align: middle;
  }
}

/* width scroll */
::-webkit-scrollbar {
  width: 0px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt30 {
  margin-top: 30px;
}

.padding {
  padding: 15px;
}

.regresar {
  cursor: pointer;
  color: green;
  font-family: "Montserrat Bold";
}

.disable {
  filter: grayscale(1) !important;
}

@media only screen and (max-width: 600px) {
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}
